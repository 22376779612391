<template>
    <div>
        <el-card class="box-card" shadow="hover">
            <div class="search">
                <el-form size="small" label-width="80px" ref="form" :model="form" :inline="true">
                    <el-form-item label="工作单位">
                        <cascader-company-name v-model="form.companyName"></cascader-company-name>
                    </el-form-item>
<!--                    <el-form-item label="日期">-->
<!--                        <el-date-picker v-model="form.year" type="year" style="width:260px" value-format="yyyy"-->
<!--                                        placeholder="选择年">-->
<!--                        </el-date-picker>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item>-->
<!--                        <el-button type="primary" plain @click="getData">查询</el-button>-->
<!--                    </el-form-item>-->
                </el-form>
            </div>
        </el-card>
        <el-row :gutter="10">
            <el-col :span="5">
                <el-card class="box-card" shadow="hover">
                    <div class="grid-content grid-con-1">
                        <i class="el-icon-s-custom grid-con-icon"></i>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{ count.djcount }}</div>
                            <div>登记人数:男{{ count.nan }}人 女{{ count.nv }}人</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="5">
                <el-card class="box-card" shadow="hover">
                    <div class="grid-content grid-con-3">
                        <i class="el-icon-folder-opened grid-con-icon"></i>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{ count.jdcount.total }}</div>
                            <div>已建档.占比{{ ((count.jdcount.total / count.countSum.total) * 100).toFixed(2) }}%</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="5">
                <el-card class="box-card" shadow="hover">
                    <div class="grid-content grid-con-2">
                        <i class="el-icon-circle-check grid-con-icon"></i>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{ count.pgcount.total }}</div>
                            <div>已评估.占比{{ ((count.pgcount.total / count.countSum.total) * 100).toFixed(2) }}%</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="5">
                <el-card class="box-card" shadow="hover">
                    <div class="grid-content grid-con-4">
                        <i class="el-icon-tickets grid-con-icon"></i>
                        <div class="grid-cont-right">
                            <div class="grid-num">{{ count.facount.total }}</div>
                            <div>新版干预方案量.占比{{ ((count.facount.total / count.countSum.total) * 100).toFixed(2) }}%</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="5">
                <el-card class="box-card" shadow="hover">
                    <div class="grid-content grid-con-5">
                        <i class="el-icon-phone grid-con-icon"></i>
                        <div class="grid-cont-right">
                            <div class="grid-num">0</div>
                            <div>随访总量.人均0人次</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
                <span>各项完成分布</span>
            </div>
            <el-col :span="6">
                <xiaowu-chart></xiaowu-chart>
            </el-col>
            <el-col :span="6">
                <report-chart></report-chart>
            </el-col>
            <el-col :span="6">
                <fxpg-chart></fxpg-chart>
            </el-col>
            <el-col :span="6">
                <xl-chart></xl-chart>
            </el-col>
        </el-card>
        <h3>员工健康情况分析</h3>
        <el-row :gutter="10">
            <el-col :span="12">
<!--                <el-card class="box-card" shadow="hover">-->
<!--                    <div slot="header" class="clearfix">-->
<!--                        <span>风险人群分布</span>-->
<!--                    </div>-->
                    <fxrqPieChart :form="form"></fxrqPieChart>
<!--                </el-card>-->
            </el-col>
            <el-col :span="12">
<!--                <el-card class="box-card" shadow="hover">-->
<!--                    <div slot="header" class="clearfix">-->
<!--                        <span>异常因数分布</span>-->
<!--                    </div>-->
                    <ycysPieChart :form="form"></ycysPieChart>
<!--                </el-card>-->
            </el-col>
        </el-row>
        <!--        <h3>高危情况分析</h3>-->
        <!--        <gwjbChart></gwjbChart>-->
        <!--        <h3>随访情况统计</h3>-->
        <!--        <el-card class="box-card" shadow="hover">-->
        <!--            <el-row>-->
        <!--                <el-empty description="暂无数据"></el-empty>-->
        <!--            </el-row>-->
        <!--        </el-card>-->

        <!-- <h3>过敏情况分析</h3>
        <el-row :gutter="10">
            <el-col :span="12">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>药物过敏分布</span>
                    </div>
                    <fxrqPieChart></fxrqPieChart>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>食物或接触过敏情况分布</span>
                    </div>
                    <ycysPieChart></ycysPieChart>
                </el-card>
            </el-col>
        </el-row>
        <h3>生活习惯分析</h3>
        <el-row :gutter="10">
            <el-col :span="12">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>吸烟情况分布</span>
                    </div>
                    <fxrqPieChart></fxrqPieChart>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>饮酒情况分布</span>
                    </div>
                    <ycysPieChart></ycysPieChart>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="10">
            <el-col :span="12">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>睡眠情况分布</span>
                    </div>
                    <fxrqPieChart></fxrqPieChart>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>运动情况分布</span>
                    </div>
                    <ycysPieChart></ycysPieChart>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="10">
            <el-col :span="12">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>饮食习惯分布</span>
                    </div>
                    <fxrqPieChart></fxrqPieChart>
                </el-card>
            </el-col>
        </el-row> -->
    </div>

</template>

<script>
    export default {
        name: "dashboard-index",
        data() {
            return {
                loading: false,
                form: {
                    companyName: "",
                    year: ""
                },
                count: {
                    djcount: 0,
                    nan: 0,
                    nv: 0,
                    jhcount: 0,
                    jhzb: 0,
                    jdcount: 0,
                    jdzb: 0,
                    facount: 0,
                    fazb: 0,
                    sfcount: 0,
                    sfrc: 0,
                    pgcount:0,
                    countSum:0
                },
            }
        },
        watch: {
            "form.companyName": {
                // eslint-disable-next-line no-unused-vars
                handler(newValue, oldValue) {
                    this.getData()
                },
                deep: true,
                immediate: true
            }
        },
        methods: {
            async getData() {
                this.loading = true
                const resp = await this.$http.post(`/company/dashboard/count`, this.form)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    let stats = resp.data.data.djcount
                    const maleStat = stats.find(stat => stat.sex === "1");
                    const femaleStat = stats.find(stat => stat.sex === "2");
                    const maleTotal = maleStat ? maleStat.total : 0;
                    const femaleTotal = femaleStat ? femaleStat.total : 0;
                    this.count = resp.data.data
                    this.count.djcount = parseInt(maleTotal) + parseInt(femaleTotal)
                    this.count.nan = maleTotal
                    this.count.nv = femaleTotal
                }
                this.loading = false
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    .box-card {
        margin: 10px 0;
    }


    .tiaoz .el-col-8 {
        text-align: center;
        font-size: 14px;
        color: #606266
    }

    .tiaoz .el-col-8 .el-button {
        margin-bottom: 8px;
        font-size: 14px;
        color: white;
        border: 1px solid #80b0d2;
        background: rgb(128, 176, 210);
    }

    .grid-content {
        display: flex;
        align-items: center;
        /*height: 130px;*/
    }

    .grid-cont-right {
        flex: 1;
        text-align: center;
        font-size: 14px;
        color: #999;
    }

    .grid-num {
        font-size: 25px;
        font-weight: bold;
    }

    .grid-con-icon {
        font-size: 30px;
        /*width: 100px;*/
        /*height: 130px;*/
        text-align: center;
        /*line-height: 130px;*/
        color: #fff;
    }

    .grid-con-3 .grid-con-icon {
        /*background: rgb(195, 191, 226);*/
        color: #78d711;
    }

    .grid-con-3 .grid-num {
        color: #78d711;
    }

    .grid-con-1 .grid-con-icon {
        /*background: rgb(128, 176, 210);*/
        color: #78d711
    }

    .grid-con-1 .grid-num {
        color: #78d711;
    }

    .grid-con-2 .grid-con-icon {
        color: #78d711;
        /*background: rgb(141, 210, 198);*/
    }

    .grid-con-2 .grid-num {
        /*color: rgb(141, 210, 198);*/
        color: #78d711;
    }

    .grid-con-4 .grid-con-icon {
        /*background: rgb(144, 147, 153);*/
        color: #78d711;
    }

    .grid-con-4 .grid-num {
        color: #78d711;
    }

    .grid-con-5 .grid-con-icon {
        /*background: rgb(250, 153, 141);*/
        color: #78d711;
    }

    .grid-con-5 .grid-num {
        color: #78d711;
    }

    .mgb20 {
        margin-bottom: 20px;
    }

    .el-col-5 {
        width: 20%;
    }
</style>
